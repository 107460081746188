import { Flex } from '@chakra-ui/react';
import { AuthPanel } from '@/components/auth';

export const QuickCreateAuth = () => {
  return (
    <Flex p="8">
      <AuthPanel
        fullWidth
        redirectTo="/magic-auth/success"
        onAuthenticated={() => window.close()}
      />
    </Flex>
  );
};
